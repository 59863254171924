import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {ThemeProvider} from "@unlockre/components-library/dist/theme-provider/";
import {PageAuth0Provider} from "@unlockre/page-tools/dist";
import {AmplitudeClientProvider} from "@unlockre/utils-amplitude/dist";
import {SplitIoProvider} from "@unlockre/utils-split-io/dist";
import type {AppProps} from "next/app";
import dynamic from "next/dynamic";
import {SWRConfig} from "swr";

import GlobalStyle from "@/components/global-style";
import {Page} from "@/components/page";
import {amplitudeClient} from "@/utils/amplitude";
import environmentVariables from "@/utils/environment/environment-variables";
import splitIoFactory from "@/utils/split-io/split-io-factory";

import {ApiClientsProvider} from "../api-clients-provider";

import {applicationTheme} from "./application-theme";

// eslint-disable-next-line @typescript-eslint/naming-convention
const LandscapeBlocker = dynamic(
  () => import("../landscape-blocker").then(module => module.LandscapeBlocker),
  {
    ssr: false
  }
);

/* eslint-disable react/jsx-max-depth */
const Application = (appProps: AppProps) => (
  <>
    <GlobalStyle />
    <PageAuth0Provider
      audience={environmentVariables.AUTH0_AUDIENCE}
      clientId={environmentVariables.AUTH0_CLIENT_ID}
      domain={environmentVariables.AUTH0_DOMAIN}
    >
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false
        }}
      >
        <ApiClientsProvider>
          <SplitIoProvider factory={splitIoFactory}>
            <AmplitudeClientProvider {...{amplitudeClient}}>
              <ThemeProvider theme={applicationTheme}>
                <Page {...{appProps}} />
                <LandscapeBlocker />
              </ThemeProvider>
            </AmplitudeClientProvider>
          </SplitIoProvider>
        </ApiClientsProvider>
      </SWRConfig>
      <ReactQueryDevtools initialIsOpen={false} />
    </PageAuth0Provider>
  </>
);
/* eslint-enable */

export default Application;
