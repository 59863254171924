import "@fontsource-variable/inter";

import {createGlobalStyle} from "styled-components";
import {normalize} from "styled-normalize";

import "cesium/Build/Cesium/Widgets/widgets.css";
import {customScrollbar} from "./custom-scrollbar";

// eslint-disable-next-line @typescript-eslint/naming-convention
const GlobalStyle = createGlobalStyle`
  ${normalize}

  html, body, #__next {
    height: 100%;
  }

  body {
    font-family: 'Inter Variable', sans-serif;
    background: #fafafb;
  }

  * {
    box-sizing: border-box;
  }

  .marker-tooltip {
    font-family: 'Inter Variable';
    font-style: normal;
    font-weight: 600;
    font-size: 5px;
    line-height: 13px;
    margin-top: -9px;
    z-index: 2;
  }

  ${customScrollbar}
`;

export default GlobalStyle;
